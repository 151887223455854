import { Button } from 'react-bootstrap'
import React, { useState } from 'react'
import '../css/Topbar.css'
import { GiHamburgerMenu } from 'react-icons/gi'
import MiniSidebar from './MiniSidebar'
import { AiFillCloseCircle, AiFillMail } from 'react-icons/ai'
import { IoIosCall } from 'react-icons/io'
import Logo from '../images/AssistanceIndiaLogo.png'
import { Link } from 'react-router-dom'

function Topbar() {
    const [toggleMiniSidebar, setToggleMiniSidebar] = useState(false)

    const showMiniSidebar = () => {
        if (toggleMiniSidebar) {
            return <div className='mini-sidebar'>
                <AiFillCloseCircle
                    onClick={() => setToggleMiniSidebar(!toggleMiniSidebar)}
                    style={{ zIndex: 99, fontSize: '25px', float: 'right' }} />
                <MiniSidebar />
            </div>
        } else {
        }
    }

    const pa = {
        margin: '10px 10px 10px 40px'
    }

    const logoStyles={
        marginTop:'10px',
        width:"200px"
    }

    return (
        <>
            <div className='nav-menu-container'>
                <div className='container d-flex justify-content-end align-items-center gap-2'>
                    <div className='contactTopStyle'>
                        <div style={pa}>
                            <IoIosCall /> +91-9847-037-111
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            <AiFillMail /> contact@robobin.in
                        </div>
                    </div>
                </div>
                <div className='container nav-menu-inner-container'>
                    <div>
                        <h2>
                            <img src={Logo} alt="" style={logoStyles} />
                        </h2>
                    </div>
                    <div>
                        <div className='nav-menu-toggle-container'>
                            <GiHamburgerMenu
                                style={{ zIndex: 98 }}
                                onClick={() => setToggleMiniSidebar(!toggleMiniSidebar)} />
                        </div>
                        <ul className='nav-menu'>
                            <li>Home</li>
                            <li>Services</li>
                            <li>Product & Features</li>
                            <li><Link to="#aboutusId">About us</Link></li>
                            <li>Contact</li>
                            <li><Button variant='success'>Request Demo</Button></li>
                        </ul>
                        <div>
                            {showMiniSidebar()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Topbar