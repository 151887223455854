import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import awardImage from '../images/shibu-vijayavedam.jpg'
import india from '../images/india.png'


const aboutImage = { india }
const aboutustext = <>
    <p style={{ textAlign: 'justify' }}>At Robobin, we are committed to providing innovative and sustainable solutions for managing organic waste. Our fully automatic organic waste converter <strong>Robobin</strong> are designed to offer a cost-effective, efficient, and eco-friendly way to compost organic waste, helping our customers reduce their environmental impact and promote sustainability.</p>
    <p style={{ textAlign: 'justify' }}>Our team of experts has years of experience in the waste management industry , We started our journey from southern part of Inida, <strong>Kochi</strong> in <strong>2018</strong> and Mr. <strong>Shibu Vijayavedam</strong> is dedicated to developing high-quality composting machines that meet the needs of our customers. We use the latest technology and materials to ensure that our products are durable, reliable, and easy to use.</p>
    {/* <p style={{ textAlign: 'justify' }}>Our company specializes in developing and producing an automatic food waste management system designed for households. Our system is a smart solution that helps homeowners reduce food waste and contribute to a more sustainable future.</p> */}
    <p style={{ textAlign: 'justify' }}>Our product is compact and easy to install, making it perfect for small homes and apartments. It uses innovative technology to process food waste quickly and efficiently, minimizing odors and reducing the need for traditional waste disposal methods.</p>
    <p style={{ textAlign: 'justify' }}>With our system, homeowners can easily separate their food waste from other household waste, ensuring that it is properly disposed of and does not end up in landfills. The system also produces high-quality compost that can be used to nourish plants and gardens, making it an eco-friendly choice for homeowners.</p>
    <p style={{ textAlign: 'justify' }}>We are committed to creating a sustainable future, and our automatic food waste management system is just one way we are helping to make a difference.</p>
</>

function AboutUsComponent() {

    const marketImage = {
        borderRadius: '600px',
        width: '100%',
        border: '5px solid white',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    }

    return (
        <div className='container mt-4' id='aboutusId'>
            <div className='section-aboutus-container p-4 about-md'>

                <div className='subsection-aboutus-award'>
                    <AnimationOnScroll
                        animateIn="animate__backInRight"
                        animateOnce={true}
                    >
                        <h1><strong>Mr. Shibu Vijayavedam</strong></h1>
                        <strong className='text-secondary'>Founder, MD - Assistance India, Robobin </strong>
                        <p></p>
                        <h5 style={{ fontFamily: 'Raleway', fontSize: '16px' }} className='text-secondary'>
                            <p>I am Shibu Vijayavedam.</p>
                            <p className='lineHeight'>Even when man claims to live by his own efforts, he is dependent on the results of the thoughts and actions of millions of predecessors and contemporaries whom he does not even know, and experiences it all without giving any reward.</p>
                            <p className='lineHeight'>Knowing this, you will find it in my every thought and action to try to make changes that will be beneficial to the contemporaries and future generations in gratitude for the many things we depended on during our own lifetime.</p>
                            {/* <p className='lineHeight'>
                                I am Mr. Shibu Vijayavedam, a young entrepreneur who is passionate about making a positive impact on the environment. After realizing the amount of domestic garbage being produced and not disposed of properly, Mr. Shibu Vijayavedam decided lauch his new venture <strong>Assistance India</strong> to take action.
                            </p>
                            <p className='lineHeight'>He invented a technology that allows people to easily manage their waste and have it disposed of in an environmentally friendly way. Robobin's initiative has quickly gained popularity among environmentally conscious individuals, and now partnering with local waste management companies to expand his services.</p>
                            <p className='lineHeight'> We are delighted about the people are now able to dispose of their waste in a responsible way and make a positive impact on the environment.</p> */}
                        </h5>
                    </AnimationOnScroll>
                </div>
                <div className='subsection-aboutus-award p-4'>
                    <AnimationOnScroll
                        animateIn="animate__backInLeft"
                        animateOnce={true} s
                    >
                        <img src={awardImage} alt='' style={marketImage} />
                    </AnimationOnScroll>
                </div>
            </div>
            <div className='mt-4 aboutus-border-container'>
                <AnimationOnScroll
                    animateIn="animate__bounceIn"
                    animateOnce={true}
                >
                    <div className='section-aboutus-container'>
                        <div className='abtus-subsection-a'>
                            <img src={india} alt='' className='w-100' />
                        </div>
                        <div className='abtus-subsection-b'>
                            <h2><strong>About US</strong></h2>
                            <p className='text-secondary' style={{ fontFamily: 'Raleway', fontSize: '16px' }}>
                                {aboutustext}
                            </p>
                        </div>
                    </div>
                </AnimationOnScroll>
            </div>
        </div>
    )
}

export default AboutUsComponent